import React, { useEffect } from 'react';
import { Hub } from 'aws-amplify';
import CloudAnimation from '../../components/animation/cloud';
import './login-success.styles.scss';

const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;
const LoginSuccess = () => {
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      if (event === 'customOAuthState') {
        window.history.replaceState({}, null, `${PORTAL_URL}${data}`);
        window.history.go();
      }
    });
  }, []);
  return (
    <div className="login-success-page">
      <div className="login-success-page--content">
        <CloudAnimation />
        <div className="login-success-page--detail">
          <h1>LOGIN SUCCESSFUL!</h1>
          <p>You will be redirected to the previous page shortly...</p>
          <p>
            If you are not redirected in 10 seconds, please click{' '}
            <a href={`${PORTAL_URL}`} data-testid="login-success">
              here
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginSuccess;
