import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { ErrorBoundary } from 'react-error-boundary';
import DashBoard from './pages/dashboard/dashboard.component';
import Orders from './pages/orders/orders.component';
import Order from './pages/order/order.component';
import Plans from './pages/plans/plans.component';
import Plan from './pages/plan/plan.component';
import Sidebar from './components/sidebar/sidebar.component';
import ErrorPage from './pages/error/error.component';
import Page404 from './pages/error/404/404.component';
import Admin from './pages/admin/admin.component';
import AccountMigration from './pages/account-migration/account-migration.component';
import AddDevice from './pages/add-device/add-device.component';
import Devices from './pages/devices/devices.component';
import Device from './pages/device/device.component';
import Landing from './pages/landing/landing.component';
import ErrorHandler from './pages/ErrorHandler';
import NetworkErrorHandler from './pages/NetworkErrorHandler';
import WebSocketErrorHandler from './pages/WebSocketErrorHandler';
import LoginSuccess from './pages/login-success/login-success.component';
import useAuthentication from './api/useAuthentication';
import checkAuthentication from './api/checkAuthentication';
import portalHandleRequest from './api/portalHandleRequest';
import useCheckNewBuild from './api/useCheckNewBuild';
import {
  setCurrentUser,
  setUserAccounts,
  setCurrentSubscriptions,
  setActiveDevices,
} from './redux/user/user.actions';
import {
  setActivePlans,
  setGrafanaData,
} from './redux/product/product.actions';
import { retrievePlansData } from './redux/shop/shop.actions';
import './App.scss';

useAuthentication();
const PORTAL_API_ENDPOINT = process.env.REACT_APP_PORTAL_API;

// eslint-disable-next-line no-unused-vars
function Fallback({ error, resetErrorBoundary }) {
  console.error(error);
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  return <ErrorHandler />;
}

const App = ({
  setCurrentUser,
  setUserAccounts,
  setCurrentSubscriptions,
  setActiveDevices,
  setActivePlans,
  setGrafanaData,
  retrievePlansData,
  currentUser,
  userAccounts,
  refreshData,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isCheckingUserDone, setCheckingUserDone] = useState(false);

  useEffect(() => {
    if (location.pathname !== '/') {
      const user = checkAuthentication();
      if (!user) {
        Auth.federatedSignIn({
          customState: window.location.pathname,
        });
      }
    }

    useCheckNewBuild();
  }, [location.pathname]);

  useEffect(() => {
    const checkCurrentUser = async () => {
      const user = await checkAuthentication();
      if (!user) {
        if (location.pathname !== '/') {
          Auth.federatedSignIn({
            customState: window.location.pathname,
          });
        }
        return console.error('Check Auth Failed... Redirect to landing page');
      }

      const cognitoEmail = user.signInUserSession.idToken.payload.email;
      // Retrieve user_accounts from DB
      const obj = {
        method: 'GET',
        url: `${PORTAL_API_ENDPOINT}/users/${cognitoEmail}`,
      };

      const response = await portalHandleRequest(obj, navigate);

      if (response.status === 200) {
        // User found in the DB

        const accountFound = (response) => {
          // Save the customer info to Redux Store
          setUserAccounts(response);

          if (response.accounts.length === 1) {
            // User has only 1 account
            setCurrentUser(response.accounts[0]);
          } else if (response.accounts.length > 1) {
            // User has more than 1 account => find the default account
            response.accounts.find((account) => {
              if (
                (localStorage.getItem('currentAccount') &&
                  localStorage.getItem('currentAccount') ===
                    account.account_id) ||
                account.default
              ) {
                setCurrentUser(account);
              }
              return null;
            });
          }

          setCheckingUserDone(true);
        };

        const accountNotFound = () => {
          navigate('/error', {
            state: {
              status: response.status,
              name: response.name,
              message: response.message,
            },
          });
        };
        return response.accounts ? accountFound(response) : accountNotFound();
      }
      if (response.status === 404) {
        // user not found in the DB. Check if there is any guest user account
        const obj = {
          method: 'GET',
          url: `${PORTAL_API_ENDPOINT}/guest-users/${cognitoEmail}`,
        };
        const res = await portalHandleRequest(obj, navigate);
        if (res.status === 200) {
          // guest account found. go to migrating page
          navigate('/account-migration', {
            state: {
              allowMigration: true,
              accounts: res,
            },
          });
        } else {
          // no guest account found. create new account
          const obj = {
            method: 'POST',
            url: `${PORTAL_API_ENDPOINT}/users`,
            contentType: 'application/json',
            data: { migrate_account: false },
          };
          const res = await portalHandleRequest(obj, navigate);
          if (res.status === 200) {
            checkCurrentUser();
          } else {
            navigate('/network-error');
          }
        }
      }
      return null;
    };

    checkCurrentUser();
  }, [refreshData]);

  useEffect(() => {
    // Get all products from store ( this will be used to add device)
    const getProducts = async () => {
      const obj = {
        method: 'GET',
        url: `${PORTAL_API_ENDPOINT}/products`,
        contentType: 'application/json',
      };
      const productsData = await portalHandleRequest(obj, navigate);
      if (productsData.status === 200 && productsData.data) {
        // Sort products to get plans
        const plans = productsData.data.filter(
          (item) => item.metadata.Type === 'Subscription'
        );

        retrievePlansData(plans);
      } else {
        console.error(productsData);
      }
    };
    getProducts();
  }, []);

  useEffect(() => {
    if (!isCheckingUserDone) return;

    // Get all active subscriptions
    const getSubscriptions = async () => {
      if (
        currentUser &&
        currentUser.stripe_customer_id &&
        currentUser.account_id
      ) {
        const obj = {
          method: 'GET',
          url: `${PORTAL_API_ENDPOINT}/subscriptions/${currentUser.stripe_customer_id}?account_id=${currentUser.account_id}`,
        };

        const subscriptions = await portalHandleRequest(obj, navigate);

        if (
          subscriptions.statusCode === 200 &&
          subscriptions.body.data.length > 0
        ) {
          setCurrentSubscriptions(subscriptions.body.data);
        }
      }
    };
    const getDevices = async () => {
      if (userAccounts && userAccounts.user_email) {
        const obj = {
          method: 'GET',
          url: `${PORTAL_API_ENDPOINT}/devices/user-email/${userAccounts.user_email}`,
        };

        const devices = await portalHandleRequest(obj, navigate);
        if (devices.status === 200) {
          setActiveDevices(devices.data);
        } else {
          console.error(devices);
        }
      }
    };
    const getPlans = async () => {
      if (userAccounts && userAccounts.user_email) {
        const obj = {
          method: 'GET',
          url: `${PORTAL_API_ENDPOINT}/plans/user-email/${userAccounts.user_email}`,
        };

        const plans = await portalHandleRequest(obj, navigate);
        if (plans.status === 200) {
          setActivePlans(plans.data);
        } else {
          console.error(plans);
        }
      }
    };

    const getUserGrafanaData = async () => {
      if (userAccounts?.grafana && userAccounts?.provisioning_completed) {
        const newObj = {};
        userAccounts.grafana.dashboards.forEach((obj) => {
          if (obj.dashboard_template_type) {
            // Set the new key with the value of 'dashboard_template_type'
            // eslint-disable-next-line no-param-reassign
            newObj[obj.dashboard_template_type] = obj;
          } else {
            newObj.edgepi_main = obj;
          }
        });
        setGrafanaData(newObj);
      } else {
        console.error('Grafana data is not available.');
      }
    };

    getPlans();
    getDevices();
    getSubscriptions();
    getUserGrafanaData();
  }, [currentUser, isCheckingUserDone, refreshData]);

  return (
    <ErrorBoundary FallbackComponent={Fallback}>
      {location.pathname !== '/404' &&
        location.pathname !== '/error' &&
        location.pathname !== '/network-error' &&
        location.pathname !== '/login-success' &&
        location.pathname !== '/connection-error' &&
        location.pathname !== '/' && <Sidebar />}

      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/login-success" element={<LoginSuccess />} />
        <Route exact path="/dashboard" element={<DashBoard />} />
        <Route exact path="/dashboard/:id" element={<DashBoard />} />
        <Route path="/orders" element={<Orders />} />
        <Route exact path="/order/:id" element={<Order />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/network-error" element={<NetworkErrorHandler />} />
        <Route path="/connection-error" element={<WebSocketErrorHandler />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/account-migration" element={<AccountMigration />} />
        <Route exact path="/add-device/:id" element={<AddDevice />} />
        <Route exact path="/add-device" element={<AddDevice />} />
        <Route path="/devices" element={<Devices />} />
        <Route exact path="/device/:id" element={<Device />} />
        <Route path="/plans" element={<Plans />} />
        <Route exact path="/plan/:id" element={<Plan />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  userAccounts: state.user.accounts,
  refreshData: state.product.refreshData,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setUserAccounts: (accounts) => dispatch(setUserAccounts(accounts)),
  setCurrentSubscriptions: (subscriptions) =>
    dispatch(setCurrentSubscriptions(subscriptions)),
  setActiveDevices: (devices) => dispatch(setActiveDevices(devices)),
  setActivePlans: (plans) => dispatch(setActivePlans(plans)),
  setGrafanaData: (plans) => dispatch(setGrafanaData(plans)),
  retrievePlansData: (products) => dispatch(retrievePlansData(products)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
