import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ErrorIcon } from '../../assets/cloud-error.svg';

import './error-comp.styles.scss';

const ErrorComp = () => {
  const navigate = useNavigate();
  return (
    <Box w="100%" className="error-comp-container">
      <div className="error-icon">
        <ErrorIcon />
      </div>

      <Flex className="error-detail" flexDirection="column" alignItems="center">
        <h3>Something went wrong</h3>
        <p>
          We are unable to process your request. Please try again or contact us
          for support.
        </p>
        <Flex mt={4}>
          <Button
            mt={6}
            className="btn-group"
            bg="#DB2F5CCC"
            color="#FFF"
            variant="solid"
            w={200}
            onClick={() => {
              navigate('/');
            }}
            _hover={{ bg: '#db2f5d99' }}
          >
            Back to Homepage
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
export default ErrorComp;
